import { useApp } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useApp";
import { useStore } from "@/core/services/store";
import { setXentralConfig } from "@/composables/useSalesChannelManagementApp";
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import { Toast } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";
import { baseUrl } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/shopifyApp";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";
import { isEmpty } from "lodash";

export const useShopifyOauth = () => {
  const store = useStore();

  const xentralConfigData = computed(
    () => store.getters.xentralConfig?.data ?? []
  );
  const route = useRoute();

  const queryState = computed(() => {
    try {
      return JSON.parse(
        atob(xentralConfigData.value?.["state"]?.replaceAll("%3D", "="))
      );
    } catch (error) {
      return xentralConfigData.value?.["state"];
    }
  });

  const { selectIntegration } = useApp();
  const { show, startMigration, selectedChannelId } = useMigrationIntegration();

  /**
   * This is called when clicking the action button to migrate the shopify app,
   * requesting a new access token from the settings area
   */
  function getAccessToken() {
    const state = store.getters.migrationOAuthState;

    let fromUrl =
      store.getters.xentralConfig?.data?.baseUrl?.replace(/\/$/, "") || "";

    // Update migration state
    Object.assign(state, {
      migration: true,
      from_url: fromUrl + "/app/app/connect-shopify",
      licenseId: store.getters.licenseId,
      project_id: store.getters?.selectedIntegration?.id,
      origin: route.params?.area,
      uuid: selectedChannelId.value?.channelId
    });

    // Construct the OAuth URL
    const params = new URLSearchParams({
      no_redirect: true,
      client_id: process.env.VUE_APP_SHOPIFY_APP_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_SHOPIFY_APP_MIGRATION_REDIRECT_URL,
      state: btoa(JSON.stringify(state))
    });

    resetXentralConfig();

    // Redirect to the OAuth URL
    window.open(`${baseUrl}?${params}`, "_parent");
  }

  async function applyShopifyOAuthValues() {
    if (isEmpty(queryState.value)) return;

    // Opens the migration modal
    if (queryState.value?.migration) {
      show.value = true;
      return;
    }

    // Opens settings area
    const selectedIntegration = store.getters.integrationProject(
      queryState.value.project_id
    );
    await selectIntegration(
      selectedIntegration,
      "settings",
      "settings",
      "wizard"
    );

    await Toast.fire({
      icon: "success",
      title: i18n.t("salesChannelManagementApp.newTokenSuccess")
    });

    resetXentralConfig();
  }

  /**
   * After successful authorization at shopify this gets called to start a migration
   * @returns {Promise<void>}
   */
  async function startShopifyOAuthMigration() {
    if (isEmpty(queryState.value)) return;

    Toast.fire({
      icon: "success",
      title: i18n.t("salesChannelManagementApp.shopify.migrationSuccess")
    }).then(() => {
      // ignore promise
    });

    selectedChannelId.value.channelId = queryState.value.uuid;
    await startMigration(queryState.value);
    resetXentralConfig();
    await store.dispatch("setMigrationOAuthState", undefined);
  }

  function resetXentralConfig() {
    //Remove state property from xentralConfig vuex store
    let xentralData = store.getters.xentralConfig;

    // Create a new object excluding the 'state' property
    let newData = { ...xentralData.data };
    delete newData.state;

    let updatedXentralConfig = { ...xentralData, data: newData };

    setXentralConfig(updatedXentralConfig);
  }

  return {
    getAccessToken,
    applyShopifyOAuthValues,
    startShopifyOAuthMigration
  };
};
