var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Modal,{attrs:{"title":_setup.stepTitle,"show-modal":_setup.show,"show-footer":!_setup.isMigrating,"disable-close":_setup.isMigrating,"close-text":"general.cancel","type":"x-large"},on:{"toggle-modal":function($event){_setup.show = $event}},scopedSlots:_vm._u([{key:"beforeClose",fn:function(){return [_c(_setup.HelpArea,{attrs:{"items":_setup.helpItems}})]},proxy:true},{key:"content",fn:function(){return [(_setup.isMigrating)?_c('div',{staticClass:"h-200px"},[_c('div',{staticClass:"alert alert-info"},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.migration.isMigrating"))+" ")]),(_setup.isMigrating)?_c(_setup.Loader):_vm._e()],1):_c('v-stepper',{attrs:{"flat":""},model:{value:(_setup.step),callback:function ($$v) {_setup.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"p-0",attrs:{"step":"1"}},[_c('div',[_c('div',[(
                  !!_setup.store.getters['loadingQueue/showLoadingBar'](
                    'loadChannels'
                  )
                )?_c('div',{staticClass:"loader"},[_c(_setup.Loader)],1):_c('div',{staticClass:"d-flex flex-wrap mt-4"},[(_setup.channels.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.migration.noChannelsFound"))+" ")]):_c('div',[_c(_setup.FormHelper,{ref:"formHelperSelect",attrs:{"form":_setup.migrationSelectField,"config":_setup.configSelectForm},model:{value:(_setup.selectedChannelId),callback:function ($$v) {_setup.selectedChannelId=$$v},expression:"selectedChannelId"}})],1)])])])]),_c('v-stepper-content',{staticClass:"p-0",attrs:{"step":"2"}},[_vm._l((_setup.forms),function(form,i){return _c('div',{key:i},[_c(_setup.FormHelper,{ref:"formHelper",refInFor:true,attrs:{"config":_setup.config,"form":form},model:{value:(_setup.model[i]),callback:function ($$v) {_vm.$set(_setup.model, i, $$v)},expression:"model[i]"}})],1)}),_c(_setup.FormHelper,{attrs:{"config":_setup.config,"form":_setup.integrationFormAllIntegrations},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}})],2),_c('v-stepper-content',{staticClass:"p-0",attrs:{"step":"3"}},[_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'salesChannelManagementApp.migration.integrationSetupConfirmation'
              )
            )}})]),_c('v-stepper-content',{staticClass:"p-0",attrs:{"step":"4"}},[(_setup.error)?[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.migration.finishedErrorText"))+" ")]),_c('p',[_vm._v(_vm._s(_setup.error))])]:[_c('div',{staticClass:"mb-4"},[(_setup.appHandbookLink && _setup.appVideoLink)?_c('span',[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.migration.finishedText")))]):_c('span',[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.migration.finishedTextSingular")))])]),(_setup.appHandbookLink)?_c('a',{staticClass:"btn btn-primary mr-2",attrs:{"href":_setup.appHandbookLink,"target":"_blank"}},[_c('i',{staticClass:"fal fa-book"}),_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.helpArea.handbook")))]):_vm._e(),(_setup.appVideoLink)?_c('a',{staticClass:"btn btn-primary",attrs:{"href":_setup.appVideoLink,"target":"_blank"}},[_c('i',{staticClass:"fal fa-play"}),_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.helpArea.video")))]):_vm._e()]],2)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex",class:_setup.step > 1 && _setup.step < 4 ? 'justify-space-between' : 'justify-content-end'},[(_setup.step !== 4)?[(_setup.step > 1)?_c('button',{staticClass:"btn btn-back",attrs:{"disabled":_setup.isMigrating,"size":"sm"},on:{"click":function($event){return _setup.switchStep(_setup.step - 1)}}},[_vm._v(" "+_vm._s(_vm.$t("general.back"))+" ")]):_vm._e(),(
            _setup.store.getters?.selectedApp?.name === 'shopify' &&
            _setup.isLastStep &&
            !_setup.model.credentials.useExistingToken &&
            !_setup.model.credentials.useCustomApp
          )?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_setup.isMigrating,"size":"sm"},on:{"click":_setup.getAccessToken}},[_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.getShopifyTokenAndMigrateExistingIntegration" ))+" ")]):(_setup.isLastStep)?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_setup.isMigrating,"size":"sm"},on:{"click":function($event){return _setup.startMigration(_setup.model)}}},[_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.migrateExistingIntegration" ))+" ")]):_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_setup.isMigrating || !_setup.selectedChannel,"size":"sm"},on:{"click":function($event){return _setup.switchStep(_setup.step + 1)}}},[_vm._v(" "+_vm._s(_vm.$t("general.next"))+" ")])]:[(_setup.error)?_c('button',{staticClass:"btn btn-primary",attrs:{"size":"sm"},on:{"click":function($event){return _setup.startMigration(_setup.model, false, true)}}},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.migration.tryMigrationAgain"))+" ")]):_c('button',{staticClass:"btn btn-primary",attrs:{"size":"sm"},on:{"click":function($event){_setup.show = false}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])]],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }