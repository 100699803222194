export const integrationForms = {
  shopify: {
    credentials: [
      {
        label: "useExistingToken",
        type: "checkbox",
        name: "useExistingToken",
        infoText: "useExistingTokenHelp",
        default: false
      },
      {
        label: "useCustomApp",
        type: "checkbox",
        name: "useCustomApp",
        infoText: "useCustomAppHelp",
        class: "mb-2",
        default: false,
        dependsOn: [
          {
            name: "useExistingToken",
            values: [false]
          }
        ]
      },
      {
        label: "newToken",
        type: "text",
        name: "newToken",
        class: "mb-2",
        infoText: "newTokenHelp",
        disableReturnType: true,
        validations: {
          required: true
        },
        dependsOn: [
          {
            name: "useCustomApp",
            values: [true]
          },
          {
            name: "useExistingToken",
            values: [false]
          }
        ]
      }
    ],
    productTaxes: [
      {
        label: "reducedTaxRateCollectionId",
        type: "text",
        name: "reducedTaxRateCollectionId",
        infoText: "reducedTaxRateCollectionIdHelp",
        disableReturnType: true,
        class: "border-top"
      },
      {
        label: "exemptedTaxCollectionId",
        type: "text",
        name: "exemptedTaxCollectionId",
        infoText: "exemptedTaxCollectionIdHelp",
        disableReturnType: true,
        class: "border-bottom"
      }
    ]
  },
  woocommerce: {},
  shopware6: {
    credentials: [
      {
        label: "id",
        type: "text",
        name: "id",
        infoText: "idHelp",
        disableReturnType: true,
        validations: {
          required: true
        }
      },
      {
        label: "secret",
        type: "text",
        name: "secret",
        infoText: "secretHelp",
        disableReturnType: true,
        validations: {
          required: true
        }
      }
    ]
  }
};

export const integrationFormAllIntegrations = [
  {
    label: "selectionStrategy",
    name: "selectionStrategy",
    type: "select",
    infoText: "selectionStrategyHelp",
    options: [
      { label: "externalReferences", value: "adoptExisting" },
      { label: "ruleBased", value: "ruleBased" }
    ],
    default: "adoptExisting"
  },
  {
    label: "syncImages",
    type: "checkbox",
    name: "syncImages",
    errorText: "syncImagesHelp",
    disableReturnType: true
  }
];
